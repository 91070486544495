import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "date": "2017-01-01T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><a parentName="p" {...{
        "href": "https://cargocollective.com/waynejohnson/HULU-Film-11-22-63"
      }}><img parentName="a" {...{
          "src": "/reading/11-22-63_wayne-johnson.jpeg",
          "alt": "11-22-63 Illustration by Wayne Johnson"
        }}></img></a></p>
    <a href="https://www.goodreads.com/review/show/762596993"><img width="49.1%" src="/reading/11-22-63-als-diner.jpg" /></a>
    <a href="https://www.goodreads.com/review/show/762596993"><img width="50.9%" src="/reading/11-22-63-derry.jpg" /></a>
    <ul>
      <li parentName="ul">{`Title: `}<strong parentName="li">{`11.22.63 `}</strong></li>
      <li parentName="ul">{`Stephen King`}</li>
    </ul>
    <p>{`My rating: ★★★★★`}</p>
    <p>{`Time travel + alternative history. Love it.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      